import React from "react";

const ReferEarn = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div>
            <h4>Refer & Earn Section</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferEarn;
